<template>
    <div class="container">
        <div class="panel">
            <div class="two">
                <div v-if="list.length > 0" style="padding: 12px">
                    <div class="title">雷达图</div>
                    <img style="width: 100%" v-if="list[activeIndex]" :src="list[activeIndex].url" />
                    <div class="left footer">
                        <div class="active" @click="reduceActiveIndex">
                            <img :src="arrowLeft" />
                        </div>
                                <div  class="active" v-if="!isTime" @click="setTimer">
                            <img :src="play" />
                        </div>
                         <div  class="active" v-if="isTime" @click="stopTimer">
                            <img :src="stop" />
                        </div>
                         <div  class="active" @click="setActiveIndex">
                            <img :src="arrowRight" />
                        </div>
                 

                        <div v-for="(item,index) in list.length" :key="item.url" :class="activeIndex === index ? 'active' : ''" @click="setActiveIndex(index)"></div>

                    </div>
                </div>
            </div>
            <Alert />
        </div>
    </div>
</template>

<script>
import Alert from '@/components/alert'
import { mapState, mapActions } from 'vuex'
import arrowLeft from '@/assets/icons/doubleleft.png'
import arrowRight from '@/assets/icons/doubleright.png'
import play from '@/assets/icons/play-circle.png'
import stop from '@/assets/icons/timeout.png'
export default {
    components: {
        Alert,
    },
    data() {
        return {
            arrowLeft,
            arrowRight,
            play,
            stop,
            activeIndex: 0,

            isTime: true, // 是否正在记时
        }
    },
    computed: {
        ...mapState('radar', ['list'])
    },
    mounted() {
        this.getList();
        this.setTimer();
    },
    beforeDestroy() {
        this.stopTimer();
    },
    methods: {
        ...mapActions('radar', ['getList']),

        setTimer() {
            this.setActiveIndex();
            this.isTime = true;
           this. timer = setInterval(() => {
            this.setActiveIndex();
    }, 1000);

        },
        stopTimer() {
            if (this.timer) {
                this.isTime = false;
                    clearInterval(this.timer);
            }
        },
        setActiveIndex(index) {
            if (typeof index === 'number') {
                this.activeIndex = index;
                return;
            }
               if (this.activeIndex < this.list.length - 1) {
                this.activeIndex++
            } else {
                this.activeIndex = 0;
            }
        },
                reduceActiveIndex() {
            if (this.activeIndex > 0) {
                this.activeIndex--
            }
        }
    }
}
</script>

<style lang="less" scoped>
.title {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 12px;
}
.footer {
    margin-top: 12px;
    div {
        height: 20px;
        width: 20px;
        margin-right: 4px;
        background-color: #7ec5be;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        img {
            width: 14px;
        }
    }
    .active {
        background-color: var(--theme-color);
    }
}
</style>